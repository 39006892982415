<template>
  <div class="auth-device-list">
    <app-list :opt="opt" @get="onGet" ref="myList"></app-list>
    <el-dialog :title="popUpTitle" :visible.sync="dialogVisiblePopUp" :close-on-click-modal="false">
      <div class="popUp">
        <el-form ref="form" :model="popForm" :rules="rules">
          <el-form-item label-width="120px" label="ID：" v-if="idShow">
            <el-input style="width: 420px;display: inline-block;" v-model="popForm.id" :disabled="true">
            </el-input>
          </el-form-item>
          <el-form-item label-width="120px" label="广告位编码：" prop="spaceCode">
            <el-select v-model="popForm.spaceCode" style="width: 420px;display: inline-block;" placeholder="请选择广告位编码"
              :disabled="idShow" clearable>
              <el-option v-for="item in spaceCodeList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label-width="120px" label="广告位：" prop="location">
            <el-input style="width: 420px;display: inline-block;" maxlength="64" v-model="popForm.location" :disabled="checkFlage">
            </el-input>
          </el-form-item>
          <el-form-item label-width="120px" label="描述：">
            <el-input type="textarea" rows="4" maxlength="100" style="width: 420px;display: inline-block;"
              placeholder="请输入" v-model="popForm.adDesc" :disabled="checkFlage" clearable>
            </el-input>
          </el-form-item>
          <el-form-item label-width="120px" label="广告状态：" prop="state">
            <div style="width: 320px;display: inline-block;">
              <el-switch style="display: block" v-model="popForm.state" active-color="#13ce66" :disabled="checkFlage"
                inactive-color="#ff4949" active-text="启用" inactive-text="禁用">
              </el-switch>
            </div>
          </el-form-item>
          <div class="dialog-footer">
            <el-button type="primary" v-if="!checkFlage" @click="submit()">提交</el-button>
          </div>

        </el-form>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  export default {
    data() {
      let _this = this;
      return {
        popUpTitle: "新增广告位",
        dialogVisiblePopUp: false,
        editFlage: 1, //1-新增 2-编辑
        opt: {
          title: "广告位管理",
          search: [],
          columns: [{
              label: "ID",
              key: "id"
            },
            {
              label: "广告位编码",
              key: "spaceCode"
            },
            {
              label: "广告位",
              key: "location"
            },
            {
              label: "描述",
              key: "adDesc"
            },
            {
              label: "创建时间",
              key: "createTime"
            },
            {
              label: "状态",
              key: "stateStr"
            },
            {
              label: "操作",
              key: "action",
              type: "action-but",
              opt: {
                list: [{
                  label: "查看",
                  on(row) {
                    _this.dialogVisiblePopUp = true;
                    _this.idShow = true;
                    _this.checkFlage = true;
                    _this.popUpTitle = "查看广告位";
                    _this.getAdvertiseSpaceDetail(row.id);
                  }
                }, {
                  label: "配置",
                  on(row) {
                    _this.$router.push({
                      path: "/main/adSpaceManagement/adConfiguration/add",
                      query: {
                        id: row.id,
                        spaceCode: row.spaceCode
                      }
                    })
                  }
                }]
              }
            }
          ],
          buttons: [{
              type: 0,
              on() {
                _this.editFlage = 1; //新增
                _this.dialogVisiblePopUp = true;
                _this.idShow = false;
                _this.checkFlage = false;
                _this.popUpTitle = "新增广告位";
                _this.popForm.state = true;
                _this.popForm.adDesc = "";
                _this.popForm.location = "";
                _this.popForm.spaceCode = "";
                // 对表单进行重置
                _this.$nextTick(() => {
                  _this.$refs["form"].resetFields();
                })
              }
            },
            {
              type: 1,
              on(data) {
                _this.editFlage = 2; //编辑
                _this.dialogVisiblePopUp = true;
                _this.idShow = true;
                _this.checkFlage = false;
                _this.popUpTitle = "编辑广告位";
                _this.getAdvertiseSpaceDetail(data.id);

              }
            },
            //暂时隐藏删除
            // {
            //   type: 2,
            //   on(data) {
            //     _this.$confirm("此操作将删除选择项, 是否继续?", "提示", {
            //       confirmButtonText: "确定",
            //       cancelButtonText: "取消",
            //       type: "warning"
            //     }).then(() => {
            //       _this.post("/landlord-service/advertiseSpace/deleteByIds", data.ids, {
            //         isUseResponse: true
            //       }).then(function (res) {
            //         _this.$message({
            //           message: "删除成功",
            //           type: "success"
            //         });
            //         data.refresh();
            //       }).catch(function (res) {
            //         _this.$message({
            //           message: res.data.msg,
            //           type: "warning"
            //         });
            //       });
            //     }).catch(function () {});
            //   }
            // }
          ]
        },
        checkFlage: false,
        idShow: false,
        popForm: {
          location: "",
          adDesc: "",
          state: true,
          spaceCode: ""
        },
        spaceCodeList: [],
        rules: {
          location: [{
            required: true,
            message: "请输入广告位",
            trigger: ['change', 'blur']
          }],
          spaceCode: [{
            required: true,
            message: "请选择广告位编码",
            trigger: ['change', 'blur']
          }],
          state: [{
            required: true,
            message: " ",
            trigger: ['change', 'blur']
          }],
        }
      };
    },
    created() {},
    activated() {
     let userInfo = this.cache.getLS("userInfo");
      // if(userInfo.roleType != '1') {//非超级管理员不展示广告配置
      //     this.opt.columns.forEach(item => {
      //     if(item.key == 'action') {
      //       item.opt.list.splice(1,1);
      //     }
      //   })
      // }
      this.post("/landlord-service/advertiseSpace/queryAdSpaceCode", {}).then(res => {
        res.forEach(item => {
          item.value = item.spaceCode;
          item.label = item.spaceCode;
        });
        this.spaceCodeList = res;
      })

      this.getMenuName(this.$route.path)
    },
    deactivated() {
      this.dialogVisiblePopUp = false;
    },
    methods: {
      onGet(opt) {
        let dto = {
          pageNum: opt.skip,
          pageSize: opt.limit,
          // ...opt.searchForm
        };
        this.post("/landlord-service/advertiseSpace/page/query", dto, {
          isUseResponse: true
        }).then(res => {
          res.data.data = res.data.list;
          for (let i = 0; i < res.data.data.length; i++) {
            let item = res.data.data[i];
            item.stateStr = ["禁用", "启用"][item.state];
          }
          opt.cb(res.data);
        });
      },
      submit() {
        let url = "/landlord-service/advertiseSpace/add";
        let message = "添加成功！";
        let operateName ='新增广告位';
        let dto = {
          location: this.popForm.location,
          adDesc: this.popForm.adDesc,
          state: this.popForm.state ? 1 : 0,
          spaceCode: this.popForm.spaceCode,
        };
        if (this.editFlage == 2) {
          url = "/landlord-service/advertiseSpace/update";
          dto.id = this.popForm.id;
          message = "编辑成功！";
          operateName ='编辑广告位';
        }
        this.$refs['form'].validate((valid) => {
          if (valid) {
            this.post(url, dto,{
              operatorFlage: true,
              operateName: operateName,
            }).then(res => {
              this.dialogVisiblePopUp = false;
              this.$refs.myList.get();
              this.$message({
                showClose: true,
                message: message,
                type: "success"
              });
            })
          } else {
            return false;
          }

        })
      },
      getAdvertiseSpaceDetail(id) {
        this.get("/landlord-service/advertiseSpace/detail/" + id).then(res => {
          this.popForm.state = res.state == 1 ? true : false;
          this.popForm.adDesc = res.adDesc;
          this.popForm.location = res.location;
          this.popForm.id = res.id;
          this.popForm.spaceCode = res.spaceCode;
        })
      }
    }
  };
</script>
<style lang="scss" scope>
  .dialog-footer {
    text-align: center;
    margin: 10px auto;
  }

  .el-dialog {
    width: 616px;
  }
</style>