var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "auth-device-list" },
    [
      _c("app-list", {
        ref: "myList",
        attrs: { opt: _vm.opt },
        on: { get: _vm.onGet }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.popUpTitle,
            visible: _vm.dialogVisiblePopUp,
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisiblePopUp = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "popUp" },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: { model: _vm.popForm, rules: _vm.rules }
                },
                [
                  _vm.idShow
                    ? _c(
                        "el-form-item",
                        { attrs: { "label-width": "120px", label: "ID：" } },
                        [
                          _c("el-input", {
                            staticStyle: {
                              width: "420px",
                              display: "inline-block"
                            },
                            attrs: { disabled: true },
                            model: {
                              value: _vm.popForm.id,
                              callback: function($$v) {
                                _vm.$set(_vm.popForm, "id", $$v)
                              },
                              expression: "popForm.id"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "120px",
                        label: "广告位编码：",
                        prop: "spaceCode"
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: {
                            width: "420px",
                            display: "inline-block"
                          },
                          attrs: {
                            placeholder: "请选择广告位编码",
                            disabled: _vm.idShow,
                            clearable: ""
                          },
                          model: {
                            value: _vm.popForm.spaceCode,
                            callback: function($$v) {
                              _vm.$set(_vm.popForm, "spaceCode", $$v)
                            },
                            expression: "popForm.spaceCode"
                          }
                        },
                        _vm._l(_vm.spaceCodeList, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "120px",
                        label: "广告位：",
                        prop: "location"
                      }
                    },
                    [
                      _c("el-input", {
                        staticStyle: {
                          width: "420px",
                          display: "inline-block"
                        },
                        attrs: { maxlength: "64", disabled: _vm.checkFlage },
                        model: {
                          value: _vm.popForm.location,
                          callback: function($$v) {
                            _vm.$set(_vm.popForm, "location", $$v)
                          },
                          expression: "popForm.location"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { "label-width": "120px", label: "描述：" } },
                    [
                      _c("el-input", {
                        staticStyle: {
                          width: "420px",
                          display: "inline-block"
                        },
                        attrs: {
                          type: "textarea",
                          rows: "4",
                          maxlength: "100",
                          placeholder: "请输入",
                          disabled: _vm.checkFlage,
                          clearable: ""
                        },
                        model: {
                          value: _vm.popForm.adDesc,
                          callback: function($$v) {
                            _vm.$set(_vm.popForm, "adDesc", $$v)
                          },
                          expression: "popForm.adDesc"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "120px",
                        label: "广告状态：",
                        prop: "state"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "320px",
                            display: "inline-block"
                          }
                        },
                        [
                          _c("el-switch", {
                            staticStyle: { display: "block" },
                            attrs: {
                              "active-color": "#13ce66",
                              disabled: _vm.checkFlage,
                              "inactive-color": "#ff4949",
                              "active-text": "启用",
                              "inactive-text": "禁用"
                            },
                            model: {
                              value: _vm.popForm.state,
                              callback: function($$v) {
                                _vm.$set(_vm.popForm, "state", $$v)
                              },
                              expression: "popForm.state"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "dialog-footer" },
                    [
                      !_vm.checkFlage
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  _vm.submit()
                                }
                              }
                            },
                            [_vm._v("提交")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }